
import { defineComponent, onMounted, reactive, ref } from "vue";

export default defineComponent({
  name: "EvaluationItem",
  props: {
    text: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: 0,
    },
    percent: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const progressBar = ref();

    let animate;

    const state = reactive({
      progressWidth: 0,
    });

    onMounted(() => {
      // 1초 progress animation
      animate = setInterval(animateProgress, 1000 / props.percent);
    });

    const animateProgress = () => {
      if (state.progressWidth >= props.percent) {
        clearInterval(animate);
      } else {
        state.progressWidth++;
        progressBar.value.style.width = state.progressWidth + "%";
      }
    };

    return { state, progressBar };
  },
});
