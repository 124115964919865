import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-827f423a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "type sub-text-s3 text-gray-second" }
const _hoisted_3 = { class: "profile" }
const _hoisted_4 = { class: "txt" }
const _hoisted_5 = { class: "name sub-text-s2" }
const _hoisted_6 = { class: "create-at b-text-2 text-gray-second" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "comment-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_more_verti_icon = _resolveComponent("more-verti-icon")!
  const _component_button_text = _resolveComponent("button-text")!
  const _component_button_dropdown_item = _resolveComponent("button-dropdown-item")!
  const _component_button_dropdown = _resolveComponent("button-dropdown")!
  const _component_list_icon = _resolveComponent("list-icon")!
  const _component_avatar = _resolveComponent("avatar")!
  const _component_input_comment = _resolveComponent("input-comment")!
  const _component_comment = _resolveComponent("comment")!
  const _component_report_article_modal = _resolveComponent("report-article-modal")!
  const _component_warning_modal = _resolveComponent("warning-modal")!
  const _component_report_comment_modal = _resolveComponent("report-comment-modal")!
  const _component_success_comment_report_modal = _resolveComponent("success-comment-report-modal")!
  const _component_card_basic = _resolveComponent("card-basic")!

  return (_openBlock(), _createBlock(_component_card_basic, {
    class: _normalizeClass(["review-item", `review-item-${_ctx.index}`])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_button_dropdown, {
        distance: "8",
        class: "more-verti__btn",
        container: `.review-item-${_ctx.index}`,
        skidding: 4
      }, {
        button: _withCtx(() => [
          _createVNode(_component_button_text, {
            padding: "0",
            "is-icon": true
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_more_verti_icon)
            ]),
            _: 1
          })
        ]),
        dropdownList: _withCtx(() => [
          _createElementVNode("ul", null, [
            (!_ctx.state.isMyself)
              ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                  _createVNode(_component_button_dropdown_item, {
                    text: "신고하기",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.openArticleReportModal()))
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", null, [
              _createVNode(_component_button_dropdown_item, {
                text: "삭제하기",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.showDeleteArticleModal()))
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["container"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_list_icon, { "fill-color": "#818287" }),
        _createTextVNode(" " + _toDisplayString(_ctx.article.community.title), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_avatar, {
          "avatar-img": _ctx.article.user?.avatar,
          size: 48
        }, null, 8, ["avatar-img"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.state.writerName), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.article.createdAtHumans), 1)
        ])
      ]),
      _createElementVNode("p", {
        class: "content b-text-1",
        innerHTML: _ctx.article.contentHtml
      }, null, 8, _hoisted_7),
      _createElementVNode("div", null, [
        _createVNode(_component_input_comment, {
          onSaveComment: _cache[2] || (_cache[2] = (value) => _ctx.actions.saveReviewComment(value))
        }),
        _createElementVNode("ul", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.comments, (comment, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `lecture-article-comment-${comment.resourceId}`
            }, [
              _createVNode(_component_comment, {
                index: index,
                comment: comment,
                "is-edited": _ctx.state.isEdited,
                onReportComment: ($event: any) => (_ctx.actions.showReportCommentModal(comment.resourceId)),
                onShowDeleteCommentModal: ($event: any) => (
              _ctx.actions.showDeleteCommentModal(comment.resourceId)
            ),
                onEditComment: _cache[3] || (_cache[3] = ($event: any) => (_ctx.actions.editComment()))
              }, null, 8, ["index", "comment", "is-edited", "onReportComment", "onShowDeleteCommentModal"])
            ]))
          }), 128))
        ])
      ]),
      (_ctx.state.showArticleReportModal)
        ? (_openBlock(), _createBlock(_component_report_article_modal, {
            key: 0,
            model: "articles",
            "model-resource-id": _ctx.article.resourceId,
            onHideModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actions.closeArticleReportModal())),
            onReportSuccess: _cache[5] || (_cache[5] = ($event: any) => (
        [_ctx.actions.successArticleReport(), _ctx.actions.closeArticleReportModal()]
      ))
          }, null, 8, ["model-resource-id"]))
        : _createCommentVNode("", true),
      (_ctx.state.showArticleDeleteModal)
        ? (_openBlock(), _createBlock(_component_warning_modal, {
            key: 1,
            "warning-title": "게시글을 삭제할까요?",
            "warning-text": "해당 게시글에 속한 댓글이 모두 삭제되며, 삭제 후에는 복구할 수 없어요.",
            "confirm-text": "삭제",
            onHideModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.actions.closeArticleDeleteModal())),
            onConfirm: _cache[7] || (_cache[7] = ($event: any) => (_ctx.actions.deleteArticle()))
          }))
        : _createCommentVNode("", true),
      (_ctx.state.showCommentReportModal)
        ? (_openBlock(), _createBlock(_component_report_comment_modal, {
            key: 2,
            model: "comments",
            "model-resource-id": _ctx.state.selectedCommentResourceId,
            onHideModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.actions.closeReportCommentModal())),
            onReportSuccess: _cache[9] || (_cache[9] = ($event: any) => (
        [_ctx.actions.successCommentReport(), _ctx.actions.closeReportCommentModal()]
      ))
          }, null, 8, ["model-resource-id"]))
        : _createCommentVNode("", true),
      (_ctx.state.showSuccessCommentReportModal)
        ? (_openBlock(), _createBlock(_component_success_comment_report_modal, {
            key: 3,
            onCloseModal: _cache[10] || (_cache[10] = ($event: any) => (_ctx.actions.closeSuccessCommentReportModal()))
          }))
        : _createCommentVNode("", true),
      (_ctx.state.showCommentDeleteModal)
        ? (_openBlock(), _createBlock(_component_warning_modal, {
            key: 4,
            "warning-title": "댓글을 삭제할까요?",
            "warning-text": "삭제 후에는 복구할 수 없어요.",
            "confirm-text": "삭제",
            onHideModal: _cache[11] || (_cache[11] = ($event: any) => (_ctx.actions.closeDeleteCommentModal())),
            onConfirm: _cache[12] || (_cache[12] = ($event: any) => (_ctx.actions.deleteComment()))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}