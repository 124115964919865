
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import ButtonDropdown from "@/pages/console/ContentShow/ButtonDropdown/ButtonDropdown.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ListIcon from "@/components/console/icons/ListIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import EvaluationItem from "@/pages/console/OpenHourReview/EvaluationItem/EvaluationItem.vue";
import InputSearch from "@/components/console/inputs/InputSearch.vue";
import FilterIcon from "@/components/console/icons/FilterIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ReviewItem from "@/pages/console/OpenHourReview/ReviewItem/ReviewItem.vue";
import { useStore } from "vuex";
import EmptyList from "@/components/console/empties/EmptyList.vue";
import { Community } from "@/types/articles";
import swal from "@/helper/swal";
import PageLoading from "@/components/console/loadings/PageLoading.vue";
import ArticleReportSuccessModal from "@/pages/console/OpenHourReview/ArticleReportSuccessModal/ArticleReportSuccessModal.vue";

export default defineComponent({
  name: "OpenHourReview",
  components: {
    PageLoading,
    EmptyList,
    ReviewItem,
    ArrowIcon,
    ButtonBasic,
    FilterIcon,
    InputSearch,
    EvaluationItem,
    CardBasic,
    ButtonDropdownItem,
    ListIcon,
    ButtonText,
    ButtonDropdown,
    PageHeader,
    ArticleReportSuccessModal,
  },
  setup() {
    const store = useStore();

    const lectureResourceId = store.getters["auth/user"].lecture
      ? store.getters["auth/user"].lecture.resourceId
      : "";

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      communities: computed(() => {
        return store.getters["lectures/lectureCommunities"].filter((item) => {
          return item.lectureResourceId === state.user.lecture.resourceId;
        });
      }),
      selectedCommunity: null as Community,
      isEmpty: computed(() => {
        return !state.filter.q && state.lectureArticles.length === 0;
      }),
      isSearchEmpty: computed(() => {
        return !!state.filter.q && state.lectureArticles.length === 0;
      }),
      filter: {
        q: "",
        communityResourceId: "",
        isLecture: true,
        order: "",
      },
      orderTypeText: computed(() => {
        switch (state.filter.order) {
          case "old":
            return "오래된 순";
          case "comment":
            return "최신댓글 순";
          default:
            return "최신순";
        }
      }),
      communityTypeText: computed(() => {
        return state.selectedCommunity ? state.selectedCommunity.title : "전체";
      }),
      lectureArticles: computed(() => {
        return store.getters["lectures/lectureArticles"];
      }),
      lectureReviews: computed(() => {
        return store.getters["lectures/lectureReviews"].filter(
          (lectureReview) => lectureReview.isShow
        );
      }),
      reviewVoteCountMax: computed(() => {
        let voteCounts = state.lectureReviews.map((item) => {
          return item.voteCount;
        });
        let result = Math.min.apply(null, voteCounts);
        if (isNaN(result)) {
          return 0;
        } else {
          return result;
        }
      }),
      pageLoading: false,
      lectureArticlesLoading: false,
      showArticleReportSuccessModal: false,
    });

    onBeforeMount(async () => {
      state.pageLoading = true;
      await getLectureCommunities();
      await getLectureArticles();
      await getLectureReviews();
      state.pageLoading = false;
    });

    const getLectureArticles = async (val = null) => {
      if (val) {
        await store
          .dispatch("lectures/getLectureArticles", state.filter)
          .then(() => {
            state[`${val}`] = false;
          });
      } else {
        await store.dispatch("lectures/getLectureArticles", state.filter);
      }
    };
    const getLectureReviews = async () => {
      await store.dispatch("lectures/getLectureReviews", {
        lectureResourceId: lectureResourceId,
      });
    };

    const getLectureCommunities = async () => {
      await store.dispatch("lectures/getLectureCommunities", {
        lectureResourceId: lectureResourceId,
      });
    };

    const actions = {
      setCommunity: async (community) => {
        state.lectureArticlesLoading = true;
        state.filter.communityResourceId = community
          ? community.resourceId
          : "";
        state.selectedCommunity = community;
        await getLectureArticles("lectureArticlesLoading");
      },
      setFilterOrder: (orderType) => {
        state.filter.order = orderType;
        getLectureArticles();
      },
      deleteArticle: (resourceId) => {
        store
          .dispatch("lectures/deleteLectureArticle", {
            articleResourceId: resourceId,
          })
          .then(() => {
            getLectureArticles();
            swal.deleteCompleteToast();
          });
      },
      showArticleReportSuccessModal: () => {
        getLectureArticles();
        state.showArticleReportSuccessModal = true;
      },
      closeArticleReportSuccessModal: () => {
        state.showArticleReportSuccessModal = false;
      },
    };

    return { state, actions, getLectureArticles };
  },
});
