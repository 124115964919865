
import { computed, defineComponent, PropType, reactive } from "vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import ListIcon from "@/components/console/icons/ListIcon.vue";
import Avatar from "@/components/console/avatars/Avatar.vue";
import InputComment from "@/components/console/comments/InputComment.vue";
import Comment from "@/components/console/comments/Comment.vue";
import ButtonDropdown from "@/pages/console/ContentShow/ButtonDropdown/ButtonDropdown.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import { ArticleItem } from "@/types/articles";
import { useStore } from "vuex";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import ReportArticleModal from "@/pages/console/OpenHourReview/ReportArticleModal/ReportArticleModal.vue";
import ReportCommentModal from "@/pages/console/OpenHourReview/ReportCommentModal/ReportCommentModal.vue";
import SuccessCommentReportModal from "@/pages/console/OpenHourReview/SuccessCommentReportModal/SuccessCommentReportModal.vue";
import helper from "@/helper";

export default defineComponent({
  name: "ReviewItem",
  components: {
    ReportCommentModal,
    ButtonDropdownItem,
    MoreVertiIcon,
    ButtonText,
    ButtonDropdown,
    Comment,
    InputComment,
    Avatar,
    ListIcon,
    CardBasic,
    WarningModal,
    ReportArticleModal,
    SuccessCommentReportModal,
  },
  props: {
    article: {
      type: Object as PropType<ArticleItem>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "syncLectureArticles",
    "successArticleReport",
    "deleteArticle",
    "reportComment",
    "deleteComment",
    "editComment",
  ],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      writerName: computed(() => {
        return helper.displayUserName(props.article.user);
      }),
      isMyself: computed(() => {
        return props.article.user.resourceId === state.user.resourceId;
      }),
      showArticleReportModal: false,
      showArticleDeleteModal: false,
      selectedCommentResourceId: "",
      showCommentReportModal: false,
      showSuccessCommentReportModal: false,
      showCommentDeleteModal: false,
      comments: computed(() => {
        return props.article.comments;
      }),
      isEdited: false,
    });

    const actions = {
      saveReviewComment: async (value) => {
        await store
          .dispatch("lectures/postLectureArticleCommunityComment", {
            model: "articles",
            resourceId: props.article.resourceId,
            content: value,
          })
          .then(() => {
            emit("syncLectureArticles");
          });
      },
      openArticleReportModal: () => {
        state.showArticleReportModal = true;
      },
      closeArticleReportModal: () => {
        state.showArticleReportModal = false;
      },
      successArticleReport: () => {
        emit("successArticleReport");
        state.showArticleReportModal = false;
      },
      closeArticleDeleteModal: () => {
        state.showArticleDeleteModal = false;
      },
      showDeleteArticleModal: () => {
        state.showArticleDeleteModal = true;
      },
      deleteArticle: () => {
        emit("deleteArticle");
        state.showArticleDeleteModal = false;
      },
      showReportCommentModal: (commentResourceId) => {
        state.selectedCommentResourceId = commentResourceId;
        state.showCommentReportModal = true;
      },
      closeReportCommentModal: () => {
        state.showCommentReportModal = false;
      },
      successCommentReport: () => {
        emit("reportComment");
        state.showSuccessCommentReportModal = true;
      },
      closeSuccessCommentReportModal: () => {
        state.showSuccessCommentReportModal = false;
        state.selectedCommentResourceId = "";
      },
      showDeleteCommentModal: (commentResourceId) => {
        state.selectedCommentResourceId = commentResourceId;
        state.showCommentDeleteModal = true;
      },
      closeDeleteCommentModal: () => {
        state.selectedCommentResourceId = "";
        state.showCommentDeleteModal = false;
      },
      deleteComment: () => {
        store
          .dispatch("lectures/deleteLectureArticleCommunityComment", {
            commentResourceId: state.selectedCommentResourceId,
          })
          .then(() => {
            emit("deleteComment");
            state.showCommentDeleteModal = false;
            state.selectedCommentResourceId = "";
          });
      },
      editComment: () => {
        emit("editComment");
      },
    };

    return { state, actions };
  },
});
