import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e14cd204"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "evaluation-item" }
const _hoisted_2 = { class: "text text-default" }
const _hoisted_3 = { class: "count" }
const _hoisted_4 = {
  ref: "progressBar",
  class: "progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.count), 1),
    _createElementVNode("div", _hoisted_4, null, 512)
  ]))
}